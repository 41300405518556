<template>
  <!--startprint-->
  <div>
    <el-button class="btn" type="primary" v-print="printobj">打印</el-button>
    <div id="print">
      <div class="name">
        {{ orienteer.name }}-{{ orienteer.school_name }}

        <!-- {{ orienteer.class_name }} -->
      </div>

      <div id="mapContainerTD" class="mapContainerTD"></div>
      <!-- <div class="name class_name">{{ orienteer.class_name }}</div> -->
      <img class="zb" src="../../assets/img/65.png" alt="" />
    </div>
  </div>
  <!--endprint-->
</template>

<script>
var T = window.T;
import { gatewaypointsMapids } from "@/api/games/index.js";
export default {
  data() {
    return {
      line: [],
      points: [],
      orienteer: {
        name: "",
        school_name: "",
      },
      printobj: {
        id: "print",
        popTitle: "跟着校长跑江湖",
      },
      marketList: [],
      matchInfo: [],
      currentMarkLat: "",
      currentMarkLng: "",
      marker: null,
      input2: "",
      total: 0,
      localsearch: null,
      studentPoints: [],
      line: null,
      id: "",
    };
  },
  created() {
    window.addEventListener("resize", this.onresize);
    this.id = this.$route.query.id;
    this.handlegetMacthDetail();

    // this.open();
  },
  mounted() {
    // this.doPrint();
  },
  beforeDestroy() {
    // 取消监听事件
    window.removeEventListener("resize", this.onresize);
  },
  methods: {
    // 监听是否全屏状态
    onresize(event) {
      // 利用屏幕分辨率和window对象的内高度来判断兼容IE
      let winFlag = window.innerHeight === window.screen.height;
      // 利用window全屏标识来判断 -- IE无效
      let isFull = window.fullScreen || document.webkitIsFullScreen;

      if (isFull === undefined) {
        this.isFullscreen = winFlag;
      } else {
        this.isFullscreen = winFlag || isFull;
      }
      console.log(winFlag); // true全屏   false不是全屏
    },
    fillScreen() {
      //全屏
      let docElm = document.documentElement;
      //W3C
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
        console.log(111);
      }

      //FireFox
      else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      }

      //Chrome等
      else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      }

      //IE11
      else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
      // 退出全屏
      //W3C
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
      //FireFox
      else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      }
      //Chrome等
      else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
      //IE11
      else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      if (this.isFullscreen) {
        this.$router.go(-1);
      }
    },
    async handlegetMacthDetail() {
      let userinfo = JSON.parse(localStorage.getItem("user"));
      let data = {
        id: this.id,
        school_id: userinfo.school_id,
      };
      await gatewaypointsMapids(data).then((res) => {
        console.log(res);
        let val = res.data.school_map;

        let data_l = {
          lat: val.center_lat,
          lng: val.center_lng,
          school_name: val.school_name,
          left_up_lng: val.left_up_lng,
          left_up_lat: val.left_up_lat,
          right_down_lng: val.right_down_lng,
          right_down_lat: val.right_down_lat,
          map_overlay_url: val.map_overlay_url,
          default_points: res.data.default_points,
          gateway_count: res.data.gateway_count,
        };
        this.currentLocation = data_l;
        this.marketList = res.data.default_points;

        this.orienteer = res.data.orienteer;

        setTimeout(() => {
          this.initwxMap();
        });
      });
    },
    initwxMap() {
      // let T = window.T

      this.wxMap = new T.Map("mapContainerTD"); // div的id
      //   let val = this.matchInfo.group_json[0].point_json[0];
      // new T.LngLat(this.currentLocation.lng, this.currentLocation.lat),
      //经度：112.94纬度：28.22
      this.wxMap.centerAndZoom(
        new T.LngLat(this.currentLocation.lng, this.currentLocation.lat),
        20
      );

      // 传参中心点经纬度，以及放大程度，最小1，最大18
      this.marketList.forEach((item) => {
        this.marker = new T.Marker(new T.LngLat(item.lng, item.lat), {
          icon: new T.Icon({
            iconUrl:
              "https://sisheng-resource.oss-cn-shenzhen.aliyuncs.com/prod/pic/dxyy/70.png",
            iconSize: new T.Point(30, 30),
          }),
        });
        this.currentMarkLng = item.lng;
        this.currentMarkLat = item.lat;
        //向地图上添加标注
        this.handleName(item.name);

        this.wxMap.addOverLay(this.marker);

        this.line = new T.LngLat(item.lng, item.lat);
        this.points.push(this.line);

        this.line = new T.Polyline(this.points, {
          color: "#537AF2",
          weight: 3,
          opacity: 1,
        });

        //向地图上添加线
        this.wxMap.addOverLay(this.line);

        console.log(this.currentLocation);
        var bd = new T.LngLatBounds(
          new T.LngLat(
            this.currentLocation.left_up_lng,
            this.currentLocation.left_up_lat
          ),
          new T.LngLat(
            this.currentLocation.right_down_lng,
            this.currentLocation.right_down_lat
          )
        );
        let img = new T.ImageOverlay(this.currentLocation.map_overlay_url, bd, {
          opacity: 1,
          alt: this.currentMarkLng.school_name,
        });
        this.wxMap.addOverLay(img);

        this.wxMap.setMinZoom(3);
        this.wxMap.setMaxZoom(24);
      });
    },
    handleName(e) {
      var latlng = new T.LngLat(this.currentMarkLng, this.currentMarkLat);
      var label = new T.Label({
        text: e,
        position: latlng,
        offset: new T.Point(-10, 0),
      });

      label.setFontColor("#FF7E64");
      label.setBackgroundColor("rgba(255, 255, 255, .76)");
      label.setBorderColor("transparent");

      // 关闭信息窗口
      this.wxMap.closeInfoWindow();
      //创建地图文本对象
      this.wxMap.addOverLay(label);
      // alert(e)
    },
  },
};
</script>

<style lang="less" scoped>
#print {
  position: relative;
}
.zb {
  position: absolute;
  left: 30px;
  top: 100px;
  width: 45px;
  height: 102px;
}
.name {
  margin-top: 20px;
  font-size: 22px;
  text-align: center;
  margin-bottom: 20px;
}
.class_name {
  margin-top: 30px;
  text-align: right;
}
/deep/.tdt-control-copyright,
/deep/.tdt-control {
  display: none;
}
.btn {
  //   float: right;
  position: absolute;
  right: 30px;
  top: 30px;
  font-size: 22px;
  z-index: 9998;
}
.mapContainerTD {
  width: 80%;
  height: 86vh;
}
</style>
